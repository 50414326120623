.card.is-loading {
  p {
    background: rgb(234, 234, 234);
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  p {
    height: 20px;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}