html {
  /* overflow-y: hidden; */
  color: rgba(35, 65, 109, 255);
}

body {
  background-color: #fafafc;
}

.mainContainer {
  height: 100vh;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: relative;
}

.logoContainer {
  display: flex;
}

.logoContainer .logo {
  max-width: 100%;
  height: auto;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.shortlistContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.shortlistProgress {
  display: flex;
  justify-content: space-around;
  margin: 0 20px;
  position: relative;
  padding-top: 25px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.shortlistProgress:before {
  content: "";
  width: 100%;
  left: 0;
  border-top: 2px dotted #7e7e7e;
  position: absolute;
  top: 0;
}

.dotted-line {
  border-top: 2px dotted #7e7e7e;
  width: 87%;
  margin-left: 90px;
  margin-bottom: 0;
}

.progressIcon {
  position: absolute;
  top: -15px;
  left: 50px;
  transform: rotate(90deg);
  width: 30px;
  height: 30px;
  color: red;
  transition: left 0.5s ease;
}

.id2 {
  left: calc(14%);
}

.id3 {
  left: calc(24%);
}

.id4 {
  left: calc(34%);
}

.id5 {
  left: calc(44%);
}

.id6 {
  left: calc(53.5%);
}

.id7 {
  left: calc(64%);
}

.id8 {
  left: calc(74%);
}

.id9 {
  left: calc(84%);
}

.id10 {
  left: calc(94%);
}

.progressDot {
  width: 15px;
  /* Adjust the size of the dot */
  height: 15px;
  /* Adjust the size of the dot */
  border-radius: 50%;
  /* Makes the outer div round */
  background: rgb(99, 169, 255);
  box-shadow: 0px 0px 0 5px rgb(173, 210, 255);
  border-radius: 50%;
  opacity: 30%;

  &.active {
    opacity: 100%;
  }
}

.progressDetails {
  text-transform: capitalize;
  opacity: 0%;
  font-size: 15px;
  width: 120px;
  white-space: nowrap;
  text-align: center;
  margin-top: 5px;

  &.active {
    opacity: 100%;
  }
}

.main {
  margin: auto;
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  min-height: 50vh;

  h3 {
    color: rgb(227, 0, 0);
    margin: 0;
    font-size: 28px;
    font-weight: 800;
    /* margin-top: 50px; */
  }

  p {
    /* margin: 0; */
    font-size: 18px;
  }

  .firstName {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 12px;
    text-align: left;
    width: 75%;
    position: relative;

    label {
      font-size: 15px;
    }

    input[type="text"] {
      width: 100%;
      height: 40px;
      border-radius: 10px;
      border: 1px solid rgb(213, 213, 213);
      box-shadow: none;
      font-size: 18px;
      padding: 5px 20px;
    }

    .mandate {
      position: absolute;
      bottom: 69px;
      left: 72px;
      color: red;
    }
  }

  .destination {
    margin: 0;
  }

  &.countryMain {
    width: auto;

    .countryName {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}

.nextButton {
  align-self: center;

  button {
    padding: 12px 50px;
    color: white;
    background-color: rgb(227, 0, 0);
    border: 1px solid rgb(227, 0, 0);
    font-size: 15px;
    border-radius: 5px;
    font-weight: 800;
    text-transform: uppercase;
    cursor: pointer;
    min-width: 260px;

    &:disabled {
      opacity: 0.7;
    }
  }

  .prevBtn {
    margin-right: 30px;
    cursor: pointer;
    width: 40px;
    height: 35px;
    border: 1px solid red;
    display: inline-block;
    color: red;
    border-radius: 5px;
    line-height: 35px;
    text-align: center;
    font-size: 15px;
  }

  &.disabled {
    button {
      background-color: rgb(227, 120, 120);
      border: 1px solid rgb(227, 120, 120);
      cursor: pointer;
    }
  }
}

.countryName {
  display: flex;
  justify-content: space-around;
  width: 65%;
  margin-top: 50px;
  transition: transform 1s ease;

  .moveUp {
    transform: translateY(-10px);
  }

  input[type="radio"] {
    display: none;
  }

  .countrySelector {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border: 1px solid rgb(172, 172, 172);
    padding: 1px;
    width: 45%;
    border-radius: 5px;
    cursor: pointer;

    label {
      padding: 7px 10px;
      font-size: 14px;
      cursor: pointer;
    }

    &.active {
      border: 1.5px solid rgb(58, 65, 193);
      background-color: rgb(203, 205, 248);
    }
  }

  .workFlowLogo {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    object-fit: cover;
  }
}

.countryDetails {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 75%;
  text-align: left;

  label {
    font-size: 14px;
  }
}

select {
  margin: 10px 0;
  height: 50px;
  font-size: 16px;
  padding: 0 10px;
  border-radius: 10px;
  border: 1px solid rgb(213, 213, 213);
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}

.levelMain {
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  flex-direction: column;
  min-height: 50vh;

  h3 {
    color: rgb(227, 0, 0);
    margin: 0;
    font-size: 28px;
    font-weight: 800;
    /* margin-top: 50px; */
  }

  p {
    /* margin: 0; */
    font-size: 18px;
  }

  input[type="radio"] {
    display: none;
  }
}

.preferredLevels {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  gap: 10px;

  .levelHeading {
    font-size: 16px;
    font-weight: bold;
  }

  .levelBody {
    display: flex;
    justify-content: center;
    row-gap: 0;

    select {
      text-transform: capitalize;
    }
  }

  .levelSelector {
    border: 1px solid rgb(213, 213, 213);
    padding: 10px 15px;
    margin: 10px;
    font-size: 16px;
    border-radius: 3px;
    cursor: pointer !important;
    text-transform: capitalize;
    transition: all 0.5s ease;

    label {
      cursor: pointer !important;
    }

    &.active {
      border-color: rgb(58, 65, 193);
      background-color: rgb(203, 205, 248);
    }
  }
}

.budgetMain {
  gap: 60px;
}

.budgetContainer {
  display: flex;
  flex-direction: column;
  width: 75%;
  text-align: left;

  .budgetLabel {
    font-size: 16px;
  }
}

.contactText {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 12px;
  text-align: left;
  width: 75%;
  position: relative;

  label {
    font-size: 15px;
  }

  input {
    width: 92%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid rgb(213, 213, 213);
    box-shadow: none;
    font-size: 18px;
    padding: 5px 20px;

    &.custom-country-input {
      width: 100%;
      height: 50px !important;
    }
  }
}

.contactSelect {
  display: flex;
  flex-direction: column;
  width: 75%;
  text-align: left;
  margin-top: 10px;
  position: relative;

  label {
    font-size: 16px;
  }
}

.studyPlanHead {
  width: 80%;
}

.studyPlan {
  gap: 20px;
}

.testPage {
  gap: 40px;
}

.prefereneceBody {
  display: flex;
  gap: 10px;
  white-space: nowrap;
  flex-wrap: wrap;
  border-radius: 20px;

  .prefereneceSelector {
    border: 1px solid rgb(213, 213, 213);
    padding: 10px 15px;
    margin: 5px;
    font-size: 15px;
    border-radius: 5px;
    transition: all 0.5s ease;
    cursor: pointer;

    label {
      cursor: pointer;
    }

    &.active {
      border-color: rgb(58, 65, 193);
      background-color: rgb(203, 205, 248);
    }
  }

  input[type="radio"] {
    display: none;
  }
}

.journey {
  h3 {
    width: 90%;
  }

  p {
    width: 70%;
    margin: 5px;
  }
}

.searchBar {
  display: flex;
  justify-content: space-between;
  position: relative;

  .searchOuter {
    position: relative;
  }

  input[type="text"] {
    width: 350px;
    height: 35px;
    border-radius: 10px;
    border: 1px solid rgb(213, 213, 213);
    box-shadow: none;
    font-size: 15px;
    padding: 5px 20px;
  }

  .searchIcon {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 20px;
    height: 20px;
    color: white;
    background-color: rgb(227, 0, 0);
    padding: 5px;
    border-radius: 5px;
  }
}

input[type="checkbox"] {
  display: none;
}

.courseContainer {
  display: flex;
  justify-content: center;

  .filter {
    width: 25%;
    height: max-content;
    border: 1px solid lightgray;
    border-radius: 5px;
    background-color: white;
  }
}

.courseList {
  width: 75%;
  margin-left: 20px;
  overflow: scroll;
}

.listContainer {
  background-color: white;
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 20px;

  &:hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1), 0px 0px 5px rgba(0, 0, 0, 0.1),
      0px 0px 5px rgba(0, 0, 0, 0.1);
  }
}

.instituteContainer {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.courseLogo {
  border: 1px solid lightgray;
  height: 60px;
  border-radius: 2px;

  img {
    width: 60px;
    height: 60px;
  }
}

.logoBox {
  width: 50px;
  height: 50px;
  color: #fff;
  background: rgb(227, 0, 0);
  line-height: 50px;
  text-align: center;
  border-radius: 5px;
  font-size: 18px;
}

.courseDesc {
  font-weight: 600;

  h3 {
    margin: 0;
    font-size: 18px;
    text-transform: capitalize;
  }

  .insName {
    font-size: 15px;
    margin: 5px 0;
    text-transform: capitalize;
  }

  .univLoc {
    font-size: 14px;
    color: #7e7e7e;
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 5px;

    img {
      width: 15px;
      height: 15px;
    }
  }

  .durationFee {
    display: flex;
    gap: 50px;

    p {
      font-size: 14px;
      color: #7e7e7e;
      margin: 10px 0;
    }
  }
}

.filter {
  .allFilter,
  .workFlowFilter,
  .levelFilter,
  .categoryFilter,
  .categoryFilter,
  .intakeFilter {
    margin: 20px;
  }

  hr {
    width: 90%;
    color: rgb(233, 233, 233);
  }
}

.allFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin: 0;
  }

  .appliedFilter {
    color: rgb(227, 0, 0);
  }
}

.rowContainer {
  display: flex;
  gap: 20px;
  flex-direction: column;

  input[type="checkbox"] {
    display: inline;
  }
}

.columnContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  input[type="checkbox"] {
    display: inline;
  }

  .columnFlex {
    display: flex;
    align-items: flex-start;
    gap: 5px;
  }
}

.courseShortlist {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid lightgray;
  margin-top: 15px;

  .campStudents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    width: 50%;

    p {
      font-size: 14px;
      color: #7e7e7e;
      margin: 10px 0;
    }
  }
}

.shortlistButton {
  padding: 10px 20px;
  margin-top: 10px;
  color: white;
  background-color: rgb(227, 0, 0);
  border: 1px solid rgb(227, 0, 0);
  font-size: 14px;
  border-radius: 5px;
  font-weight: 800;
  text-transform: capitalize;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
  }
}

.searchBar {
  margin-bottom: 20px;

  .logoContainer {
    display: flex;

    .logo {
      width: auto;
    }
  }
}

.loadMore {
  font-size: 16px;
  padding: 10px 20px;
  border: 1px solid rgb(227, 0, 0);
  color: rgb(255, 255, 255);
  background-color: rgb(227, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.courseLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  padding: 25px;
}

.instDesc {
  h4 {
    margin: 15px 0;
  }

  p,
  span {
    font-size: 15px;
    color: rgb(35, 65, 109) !important;
    margin: 5px 0;
    font-weight: 400;
  }
}

.toggleAccordian {
  transition: all 0.5s ease;
  visibility: hidden;
  opacity: 0;
  height: 0;
}

.toggleAccordian.show {
  visibility: visible;
  opacity: 1;
  height: auto;
}

.thankyouContainer {
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  height: 60vh;

  h3 {
    color: rgb(227, 0, 0);
    margin: 0;
    font-size: 28px;
    font-weight: 800;
    margin-top: 50px;

    span {
      display: block;
    }
  }

  p {
    font-size: 18px;
    width: 70vh;
    margin-top: 0;
  }

  span {
    margin: 5px;
  }

  .counsellorDtls {
    img {
      max-width: 100px;
      height: auto;
    }

    h4 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;

      span {
        display: block;
        font-size: 16px;
        font-weight: 400;
      }
    }

    h6 {
      margin-top: 0;
      font-size: 15px;
    }
  }
}

.text-center {
  text-align: center;
}

/* DropDown */
.profileOuter {
  position: relative;
  display: flex;
  align-items: center;
  gap: 25px;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;

  span {
    display: block;
    padding-bottom: 10px;
  }

  .dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    z-index: 1;
    border-radius: 5px;
    right: -30px;
    transition: all 0.5s ease;
    visibility: hidden;
    opacity: 0;
    margin-top: 20px;
    overflow: hidden;
    border: 1px solid lightgray;
    color: red;

    p {
      margin: 0;
      padding: 10px 30px;
    }
  }

  &:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
    margin-top: 0px;
  }
}

.mobile-text {
  display: none;
}

.crFilterButton {
  max-width: 150px;
  font-size: 14px;
  padding: 10px 20px;
  border: 1px solid rgb(227, 0, 0);
  color: rgb(255, 255, 255);
  background-color: rgb(227, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  display: none;
}

.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}

@media (max-width: 768px) {
  .crFilterButton {
    display: block;
  }
  .mainContainer {
    padding: 30px;
    height: auto;
    gap: 15px;
    .logoContainer {
      justify-content: center;
    }
    .shortlistProgress {
      display: none;
    }
    .formContainer {
      margin-top: 10%;
      min-height: 60vh;
      .main {
        width: 100%;
        min-height: auto;
        h3 {
          font-size: 20px;
          line-height: 30px;
          max-width: 400px;
          margin-bottom: 15px;
        }
        p {
          font-size: 15px;
          line-height: 20px;
          max-width: 300px;
          margin-top: 0;
        }
        .firstName {
          width: 100%;
          margin-top: 15px;
          input[type="text"] {
            padding: 5px 0 5px 15px;
            width: 97%;
          }
        }
        &.countryMain {
          .countryName {
            flex-wrap: wrap;
            gap: 10px;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin: 30px 0;
            .countrySelector {
              max-width: 200px;
              gap: 20px;
              width: 100%;
            }
          }
        }
        .prefereneceBody {
          justify-content: center;
        }
        .contactText,
        .contactSelect,
        .budgetContainer {
          width: 100%;
        }
      }
      .levelMain {
        h3 {
          font-size: 20px;
          line-height: 26px;
          max-width: 400px;
          margin-bottom: 15px;
        }
        .preferredLevels {
          .levelBody {
            flex-wrap: wrap;
            .levelSelector {
              font-size: 14px;
            }
            select {
              width: 100%;
            }
          }
        }
      }
      .nextButton {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .prevBtn {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          line-height: 40px;
        }
        button {
          min-width: 160px;
          font-size: 14px;
        }
      }
    }
  }

  .mobile-text {
    display: block;
  }
  .desk-text {
    display: none;
  }

  .dropdown {
    .dropdown-content {
      right: 0;
    }
  }

  /* Course Finder Page */
  .searchBar {
    .profileOuter {
      .searchOuter {
        display: none;
      }
    }
    .logoContainer {
      .logo,
      .logoBox {
        max-width: 200px;
      }
    }
  }
  .courseContainer {
    flex-direction: column;
    .filter {
      width: 100%;
      margin-bottom: 15px;
      .mobileFilter {
        display: none;
        &.active {
          display: block;
        }
      }
    }
    .courseList {
      width: 100%;
      margin: 0;
      .listContainer {
        padding: 15px;
        .courseLogo {
          display: none;
        }
        .courseDesc {
          h3 {
            font-size: 16px;
            margin-bottom: 10px;
          }
          .durationFee {
            flex-direction: column;
            gap: 15px;
            p {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .thankyouContainer {
    width: 100%;
    flex-wrap: nowrap;
    h3 {
      font-size: 20px;
      line-height: 25px;
      max-width: 400px;
      margin-bottom: 15px;
      span {
        display: inline-block;
      }
    }
    p {
      font-size: 15px;
      line-height: 23px;
      max-width: 300px;
      margin-top: 0;
      width: 100%;
    }
  }

  .courseShortlist {
    align-items: flex-start;
    .campStudents {
      display: block;
    }
  }
  .react-confirm-alert-body {
    width: auto !important;
  }
}
